<template>
  <f7-toolbar tabbar labels position="bottom" class="product-toolbar alt-product-toolbar toolbar-product-view">
    <f7-link href="javascript:;" text="Home" @click="addToWishList()" class="cellar-btn">
      <font-awesome-icon class="cellar-icon" v-if="Prod.isInWishlist" :icon="['fas', 'wine-bottle']" fixed-width />
      <font-awesome-icon class="cellar-icon" v-else :icon="['fal', 'wine-bottle']" fixed-width />
      <span class="cellar-text">{{ Prod.isInWishlist ? $t.getTranslation('LBL_ADDED_TO_CELLAR') : $t.getTranslation('LBL_ADD_TO_CELLAR') }}</span>
    </f7-link>

    <ChatNavigationComponent :productkey="product.ProductKey" :isFuture="product.IsFuture" class="fill chat" ref="chatnavcomponent" />

    <ShareComponent
      v-if="product && product.ProductKey !== '' && !$h.canAddToCart()"
      ref="shareComponent"
      :share-data="{
        type: 'GENERIC',
        shareLink: $h.getShareLink('Product', product.ProductKey),
        title: $h.getAttribute(product, $t.getLanguage(), 'Name'),
        description: $h.getAttribute(product, $t.getLanguage(), 'Description'),
        qoute: $h.getAttribute(product, $t.getLanguage(), 'Description'),
        media: $h.getImage(product?.Images?.length ? product.Images[0].FileName : null, 'PRODUCT')
      }"
    />

    <template v-if="product.IsFuture == 0">
      <span v-if="product && product?.GroupBuyId > 0 && isButtonDisabled" class="coming-soon">{{ $t.getTranslation('LBL_START') }} {{ $h.formatDateFromNow(product?.GroupBuy?.StartDate) }}</span>

      <f7-button v-if="$h.canAddToCart()" :disabled="isButtonDisabled" class="fill" href="javascript:;" @click="addToCart()">
        {{ $t.getTranslation('LBL_ADD_TO_CART') }}
      </f7-button>
      <f7-link v-else class="fill" href="javascript:;" text="Scan" @click="copyLink()" style="width: 210px; line-height: 1.2; font-size: 14px"> <div v-html="$t.getTranslation('LBL_COPY_LINK')"></div> </f7-link>
    </template>

    <f7-link v-else class="fill" href="javascript:;" text="Scan" @click="contact($refs)" style="font-size: 12px"> {{ $t.getTranslation('LBL_CHAT_FOR_SUPPLY_DETAILS') }} </f7-link>
  </f7-toolbar>
</template>

<script>
import { defineComponent, ref, inject, computed, defineAsyncComponent } from 'vue'
import dayjs from 'dayjs'
import { helpers } from '@/utils/helpers.js'
import { post } from '@/utils/axios'
import { useStore } from '@/store'
import { f7 } from 'framework7-vue'

//import ChatNavigationComponent from '@/components/chat/ChatNavigationComponent.vue'
//import ShareComponent from '@/components/ShareComponent.vue'

export default defineComponent({
  name: 'ProductToolbarComponent',
  components: {
    ChatNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "chat-nav" */ /* webpackMode: "lazy" */ '@/components/chat/ChatNavigationComponent.vue')),
    ShareComponent: defineAsyncComponent(() => import(/* webpackChunkName: "chat-nav" */ /* webpackMode: "lazy" */ '@/components/ShareComponent.vue'))
  },
  props: { kol: String, postkey: String, product: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const $t = inject('$translation')
    const $f7router = f7.views.get('#main-view').router

    let Prod = ref({ isInWishlist: props.product.isInWishlist })

    const isButtonDisabled = computed(() => {
      if (props?.product?.GroupBuy && props?.product?.GroupBuy?.StartDate) {
        let currentDate = dayjs()
        let startDate = dayjs(props?.product?.GroupBuy?.StartDate)
        let dateDiff = startDate.diff(currentDate, 'day', true)

        return dateDiff > 0 ? true : false
      }

      return false
    })

    const checkItem = async () => {
      let Items = store.getters['cart/getItems']
      let found = _.find(Items, (r) => {
        return r.ProductKey == props.product.ProductKey
      })
      if (!found) {
        //if item not found add to cart
        addToCart()
      } else {
        //if item found just navigate to cart so user can checkout immediately
        $f7router.navigate({ name: 'cartPage' })
      }
    }

    const addToCart = async () => {
      if (!helpers.isUserLogin()) {
        store.dispatch('user/setData', {
          ProductCode: props.product.ProductKey
        })
        return $f7router.navigate({ name: 'loginPage' })
      }

      if (props?.product?.IsSubSkuBundle > 0 && !(props?.product?.StatusCode == 'ACTIVE')) {
        helpers.createNotification({ title: $t.getTranslation('LBL_ERROR'), message: $t.getTranslation('MSG_ERR_CANT_BUY_SUB_PRODUCT_BUNDLE'), type: 'error' })
        return
      }

      if (props?.product.InventoryStatus == 'OUT_OF_STOCK') {
        helpers.createNotification({ title: $t.getTranslation('LBL_ERROR'), message: $t.getTranslation('MSG_ERR_OUT_OF_STOCK_ITEM'), type: 'error' })
        return
      }

      let Qty = 1
      if (props?.product?.QtyPerUser && props?.product?.ProductSaleStatus == 'ACTIVE') {
        Qty = props.product.QtyPerUser
      }

      let ret = await post('/cart/item/add', { KolCode: props.kol || null, PostKey: props.postkey || null, ProductKey: props.product.ProductKey, Qty: Qty })
      if (!ret) return
      store.dispatch('cart/setData', { CartItems: ret.Cart.ItemList || [] })

      $f7router.navigate({ name: 'cartPage' })
    }

    const contact = ($refs) => {
      $refs.chatnavcomponent.navigateNow()
      return
    }

    const addToWishList = async () => {
      if (!helpers.isUserLogin()) {
        return $f7router.navigate({ name: 'loginPage' })
      }

      let url = !Prod.value.isInWishlist ? '/wishlist/item/add' : '/wishlist/item/remove'
      let ret = await post(url, { ProductKey: props.product.ProductKey })
      if (!ret) return
      Prod.value.isInWishlist = !Prod.value.isInWishlist
      if (Prod.value.isInWishlist) {
        helpers.createNotification({
          type: 'success',
          title: $t.getTranslation('LBL_SUCCESS'),
          message: $t.getTranslation('LBL_ADDED_TO_CELLAR')
        })
      } else {
        helpers.createNotification({
          type: 'success',
          title: $t.getTranslation('LBL_SUCCESS'),
          message: $t.getTranslation('LBL_REMOVED_FROM_CELLAR')
        })
      }
    }

    return {
      isButtonDisabled,
      Prod,
      addToWishList,
      addToCart,
      checkItem,
      contact,
      store
    }
  },
  methods: {
    copyLink() {
      this.$refs.shareComponent.showShareSheet()
    }
  }
})
</script>

<style scoped>
.alt-product-toolbar {
  bottom: 74px;
}
.md .product-toolbar .toolbar-inner a.fill.chat {
  width: 60px;
  height: 100%;
  padding: 0px;
  border-radius: 0px;
  background-color: #1a64ca;
  margin-left: auto;
}
.md .product-toolbar .toolbar-inner .coming-soon {
  position: absolute;
  top: -30px;
  right: 0px;
  font-size: 14px;
  text-transform: uppercase;
  background: #fff;
  padding: 5px 20px;
  font-weight: 400;
}

.cellar-text{
  margin-left: 10px;
}


</style>
